import { ApplicationController } from './application_controller.js'
import {
  Tabulator,
  FormatModule,
  EditModule,
  FrozenColumnsModule,
  FilterModule,
  ResizeColumnsModule
} from 'tabulator-tables'
Tabulator.registerModule([
  FormatModule,
  EditModule,
  FrozenColumnsModule,
  FilterModule,
  ResizeColumnsModule
])
export default class extends ApplicationController {
  static targets = ['formField', 'grid']
  static values = {
    fees: Array,
    payScales: Array,
    showAgeGroup: Boolean,
    editable: Boolean
  }

  connect() {
    console.log('fees controller')
    console.log('fees', this.feesValue)
    this.table = new Tabulator(this.gridTarget, {
      height: '311px',
      data: this.feesValue,
      // autoColumns: true,
      columns: this.tableDefinitions
      // columns:[
      //     {title:"Name", field:"name", width:150, editor:"input"},
      //     {title:"Location", field:"location", width:130, editor:"autocomplete", editorParams:{allowEmpty:true, showListOnEmpty:true, values:true}},
      //     {title:"Progress", field:"progress", sorter:"number", hozAlign:"left", formatter:"progress", width:140, editor:true},
      //     {title:"Gender", field:"gender", editor:"select", editorParams:{values:{"male":"Male", "female":"Female", "unknown":"Unknown"}}},
      //     {title:"Rating", field:"rating",  formatter:"star", hozAlign:"center", width:100, editor:true},
      //     {title:"Date Of Birth", field:"dob", hozAlign:"center", sorter:"date", width:140, editor:dateEditor},
      //     {title:"Driver", field:"car", hozAlign:"center", editor:true, formatter:"tickCross"},
      // ],
    })

    // Prevent form submission on enter key press
    this._listener = (event) => {
      if (event.key === 'Enter') {
        let activeElement = document.activeElement;
        let form = activeElement.closest('form');

        if (form && this.gridTarget.contains(activeElement)) {
          event.preventDefault();

          const focusableElements = this.gridTarget.querySelectorAll('input')
          const index = Array.prototype.indexOf.call(focusableElements, document.activeElement)
          const nextIndex = (index + 1) % focusableElements.length;
          focusableElements[nextIndex].focus();
        }
      }
    }
    document.addEventListener('keydown', this._listener);
  }

  get tableDefinitions() {
    let edited = (cell) => {
      let row = cell.getData()
      row.changed = true
      this.table.updateData([row])
      console.log('cell edited!', cell)
      console.log('value', cell.getValue())
      console.log('row', cell.getData())
      console.log('field', cell.getField())
      console.log('table', this.table)
      console.log('table data', this.table.getData())
      this.formFieldTargets.forEach((element) => {
        console.log('found element', element)
        element.value = JSON.stringify(this.table.getData())
      })
    }
    let definitions = [
      {
        title: 'Pattern',
        field: 'pattern',
        frozen: true,
        resizable: true,
        headerFilterPlaceholder: 'Search...',
        headerFilter: 'input',
        editorParams: { search: true }
      },
      {
        title: 'Position',
        field: 'position',
        frozen: true,
        resizable: true,
        headerFilterPlaceholder: 'Search...',
        headerFilter: 'input',
        editorParams: { search: true }
      },
      { title: 'ID', field: 'id', visible: false },
      {
        title: 'Default Fee',
        field: 'default_fee',
        editable: this.editableValue,
        editor: this.editableValue,
        formatter: 'money',
        cellEdited: edited,
        hozAlign: 'right'
      }
    ]
    if (this.showAgeGroupValue) {
      definitions.unshift({
        title: 'Age Group',
        field: 'age',
        frozen: true,
        resizable: true,
        headerFilter: 'input',
        headerFilterPlaceholder: 'Search...',
        editorParams: { search: true }
      })
    }
    this.payScalesValue.forEach((ps) => {
      let column = {
        field: `pay_scale_${ps.id}`,
        title: ps.name,
        cellEdited: edited,
        editable: this.editableValue,
        editor: this.editableValue,
        formatter: 'money',
        hozAlign: 'right',
        resizable: true
      }
      definitions.push(column)
    })
    return definitions
  }

  disconnect() {
    document.removeEventListener('keydown', this._listener);
  }
}
